/* PrivacyPolicyPage.css */

.privacy-policy-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 130px 30px 100px;
  line-height: 1.5;
  color: white;
  border-radius: 8px;
}

.privacy-policy__title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
}

.privacy-policy__date {
  font-size: 0.9rem;
  margin-bottom: 2rem;
  text-align: center;
}

.privacy-policy__section-title {
  font-size: 1.5rem;
  margin-top: 5rem;
  margin-bottom: 3rem;
  border-bottom: 2px solid white;
  padding-bottom: 0.5rem;
}

.privacy-policy-page p {
  margin-bottom: 1rem;
}

.privacy-policy-page ul {
  list-style-type: disc;
  margin-left: 2rem;
  margin-bottom: 1rem;
}

.privacy-policy-page ul li {
  margin-bottom: 0.5rem;
}

.privacy-policy-page strong {
  font-weight: bold;
}

@media (max-width: 768px) {
  .privacy-policy__title {
    font-size: 1.75rem;
  }

  .privacy-policy__section-title {
    font-size: 1.25rem;
  }
}
