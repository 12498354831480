/* Start General styles */
.png__sequence {
  width: 100%;
  /* keep it long to reduce chances of overscroll through the section */
  height: 2000vh;
  position: relative;
}

@media (max-width: 700px) {
  .png__sequence {
    height: 1000vh; /* a bit longer to reduce chances of overscroll */
  }
}

.png__sequence .wrapper {
  position: sticky;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.png__sequence .wrapper-relative {
  position: relative;
  height: 100%;
  width: 100%;
}

/* video */
.png__sequence__video {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  z-index: 1;
}

.png__sequence__video.hidden {
  height: 0;
  opacity: 0;
}

.png__sequence__video.visible {
  height: 100%;
  opacity: 1;
}

.btn-switch {
  position: absolute;
  top: 100px;
  left: 100px;
  z-index: 500;
}

/* video */

.skip-animation_btns-wrapper {
  position: sticky;
  z-index: 70;
  top: calc(100% - 95px);
}

@media (max-width: 700px) {
  .skip-animation_btns-wrapper {
    top: calc(100% - 75px); /* don't forget to align corner buttons */
  }
}

.skip-animation_btns {
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;
  color: black;
  border: none;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  margin: auto;
  padding: 20px 0;
}

.skip-animation_btns_btn {
  background: transparent;
  padding: 0;
  border: 0;
  transition: 200ms;
}

.skip-animation_btns_btn.disabled {
  opacity: 0.5;
}

.skip-animation_btns_btn.btn-up {
  transform: rotate(-90deg);
}

.skip-animation_btns_btn.btn-down {
  transform: rotate(90deg);
}

.skip-animation_btns_btn .btn-circle {
  height: 44px;
  width: 44px;
}

.skip-animation_btns_btn.disabled .btn-circle {
  cursor: default;
  scale: 1 !important;
}

.skip-animation_btns_btn.disabled .btn-circle svg {
  animation: none !important;
}

.skip-animation_btns_text {
  width: min-content;
  text-align: center;
  margin: 0;
}

.png__sequence__canvas {
  max-height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  position: sticky;
  z-index: 1;
  object-fit: cover;
}

.png__sequence__text_part {
  position: sticky;
  /* position: absolute; */
  top: 150px;
  right: 30px;
  max-width: 320px;
  z-index: 40;
  transition: 300ms ease-in-out;
  opacity: 0;
  margin-left: auto;
  padding: 20px 0;
}

@media (min-width: 700px) {
  #text-stage-1,
  #text-stage-2 {
    margin-top: -50%;
  }
}


@media (max-width: 1200px) {
  .png__sequence__text_part {
    max-width: 270px;
    right: 20px;
  }
}

@media (max-width: 1100px) {
  .png__sequence__text_part {
    top: calc(100% - 270px);
    left: 0;
    margin-right: auto;
    margin-left: 150px;
  }
}

@media (max-width: 950px) {
  .png__sequence__text_part {
    margin-left: 100px;
  }
}

@media (max-width: 850px) {
  .png__sequence__text_part {
    margin-left: 70px;
  }
}

@media (max-width: 700px) { 
  .png__sequence__text_part {
    top: calc(100% - 180px);
    max-width: unset;
    margin-left: 20px;
    margin-right: 70px;
  }
}

.png__sequence__text_part.visible {
  animation: textAppear 300ms;
  animation-delay: 300ms;
  animation-fill-mode: forwards;
}

.png__sequence__text_part.hidden {
  animation: textDisappear 300ms;
  animation-fill-mode: forwards;
}

.png__sequence__text_part p:first-of-type {
  font-weight: 700;
  font-size: 40px;
  margin: 0 0 20px;
}

@media (max-width: 1200px) {
  .png__sequence__text_part p:first-of-type {
    font-size: 32px;
    margin: 0 0 10px;
  }
}

@media (max-width: 700px) { 
  .png__sequence__text_part p:first-of-type {
    font-size: 22px;
  }
}

.png__sequence__text_part p:nth-of-type(2) {
  font-size: 20px;
  margin: 0;
}

@media (max-width: 1200px) {
  .png__sequence__text_part p:nth-of-type(2) {
    font-size: 18px;
  }
}

@media (max-width: 700px) { 
  .png__sequence__text_part p:nth-of-type(2) {
    font-size: 14px;
  }
}

@keyframes textAppear {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes textDisappear {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-50px);
    opacity: 0;
  }
}

.scroll-sequence-section {
  width: 100%;
  height: 200vh;
  position: relative;
}

.scroll-sequence-video,
.scroll-sequence-video-reverse {
  position: sticky;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  transition: 1ms;
}

.scroll-sequence-video.is-hidden,
.scroll-sequence-video-reverse.is-hidden {
  opacity: 0;
}


/* End General styles */

/* Start Header styles */

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  padding: 15px 100px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1100px) {
  .header {
    padding: 15px 50px;
  }
}

@media (max-width: 700px) {
  .header {
    padding: 15px 20px;
  }
}

.header_logo {
  width: 90px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

@media (max-width: 641px) {
  .header_logo {
    width: 80px;
  }
}

.logo-container img {
  opacity: 1;
}

.header_nav {
  display: flex;
  align-items: center;
  gap: 30px;
}

.header_nav-toggle_icon {
  width: 15px;
}

.header_nav-close {
  position: absolute;
  top: 30px;
  right: 30px;
}

@media (min-width: 642px) {
  .header_nav-wrap #nav-input-line {
    display: none;
  }
}

@media (max-width: 641px) {
  .header_nav-wrap {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    z-index: 100;
    will-change: transform;
    transition-property: transform;
    transition-duration: 1500ms;
    transition-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    z-index: 90;
  }

  .header_nav-wrap.visible {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    pointer-events: initial;
    visibility: visible;
  }
  
  .header_nav-wrap.hidden {
    transform: translate3d(100%, 0, 0);
  }

  .header_nav-blur {
    flex: 1;
    background-color: rgba(255, 255, 255, 0.2); /* Add some background color with opacity */
    backdrop-filter: blur(6px);
    height: 100%;
  }

  .header_nav {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--medium-grey-hover);
    height: 100%;
    padding: 60px;
    max-height: 100%;
    overflow-y: auto;
  }

  .header_contact .btn-circle {
    display: none;
  }

  .header_nav-wrap #nav-input-line {
    border: none;
    height: 1px;
    width: 100%;
    background-color: var(--dark-grey);
    z-index: 1;
    opacity: 30%;
    margin: 28px -10px;
    margin-top: 0;
    margin-bottom: 0;
    left: 0;
    right: 0;
    transform-origin: 0 50%;
    transition: transform 800ms cubic-bezier(0.33, 1, 0.68, 1);
  }
  
  .header_nav-wrap.hidden .input-line {
    transform: scaleX(0);
  }
  
  .header_nav-wrap.visible .input-line {
    transition-delay: 1000ms;
    transform: scaleX(1);
  }
  
  .header_nav-wrap #nav-input-line:after {
    background: linear-gradient(-90deg, var(--medium-grey-hover), rgba(203, 251, 69, 0));
    content: "";
    position: absolute;
    height: 1px;
    width: 30px;
    right: 0;
    height: 1px;
    z-index: 2;
  }
  
  .header_nav-wrap #nav-input-line:before {
    background: linear-gradient(90deg, var(--medium-grey-hover), rgba(203, 251, 69, 0));
    content: "";
    position: absolute;
    height: 1px;
    width: 30px;
    left: 0;
    z-index: 2;
  }
}

@media (max-width: 500px) {
  .header_nav-blur {
    display: none;
  }
}

.header_nav_link,
.header_contact_text {
  font-size: 16px;
  cursor: pointer;
  color: white;
  /* text-shadow: 1px 2px rgba(0, 0, 0, 0.1); */
  transition: 200ms;
  background-color: transparent;
  border: none;
}

.header_nav_link.disabled {
  cursor: default;
}

.header_nav_link.disabled .location-dot {
  display: none;
}

@media (max-width: 641px) {
  .header_nav_link,
  .header_contact_text {
    text-align: left;
    font-size: 30px;
    width: 100%;
    padding: 0;
    color: rgb(38, 38, 38) !important;
    /* to avoid changing color (when navigate through nav link) in mobile */
  }

  .header_contact {
    width: 100%;
  }
}

.header--dark .header_nav_link,
.header--dark .header_contact_text {
  color: rgb(38, 38, 38);
}

.header_nav_link {
  position: relative;
}

.header_nav_link .location-dot {
  position: absolute;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  transition: background-color 200ms ease-in-out;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 45px;
  transition: top 400ms, opacity 400ms;
  animation-duration: 800ms;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  opacity: 0;
  animation-delay: 940ms;
}

@media (max-width: 641px) {
  .header_nav_link .location-dot {
    display: none;
  }
}

.header--light .header_nav_link .location-dot {
  background-color: #F3F3F3;
}

.header--dark .header_nav_link .location-dot {
  background-color: var(--black-2);
}

.header_nav_link:hover .location-dot {
  opacity: 1;
  top: 30px;
}

.header_contact_text {
  margin: 0;
}

.header_contact {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0;
  border: none;
  background: transparent;
}


/* End Header styles */

/* Start Footer styles */

.footer {
  background-color: white;
  padding: 40px 50px 10px;
}

@media (max-width: 900px) {
  .footer {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 641px) {
  .footer {
    padding-left: 20px;
    padding-right: 60px;
  }
}

.footer_contacts {
  display: flex;
  gap: 30px;
}

@media (max-width: 950px) {
  .footer_contacts {
    flex-direction: column;
    margin-top: 50px;
  }
}

@media (max-width: 641px) {
  .footer_contacts {
    gap: 20px;
  }
}

.footer_contacts_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 160px;
}

@media (max-width: 950px) {
  .footer_contacts_item {
    display: grid;
    grid-template-columns: 45px auto;
    grid-template-rows: auto auto;
    width: 100%;
    text-align: left;
  } 
}

.footer_contacts_item .footer_contacts_item_phone {
  font-size: 16px;
}

.footer_contacts_item img {
  height: 35px;
}

@media (max-width: 950px) {
  .footer_contacts_item img {
    height: 25px;
    grid-row: 1 / 3;
    margin-bottom: auto;
    margin-top: 2px;
  }
}

.footer_contacts_item h6 {
  font-size: 18px;
  margin: 20px 0 10px;
}

@media (max-width: 950px) {
  .footer_contacts_item h6 {
    margin: 0;
  }
}

.footer_contacts_item p {
  font-size: 14px;
  margin: 10px 0 0;
  text-decoration: none;
}

.footer_logo {
  height: 90px;
  object-fit: contain;
}

@media (max-width: 950px) {
  .footer_logo {
    height: 70px;
  }
}

.footer .contact-data {
  margin-right: 50px;
}

.footer .contact-data p {
  font-size: 15px;
  margin: 10px 0;
  color: var(--dark-grey);
}

.footer .contact-data i {
  margin-right: 10px;
  color: var(--dark-grey);
}

.footer_top,
.footer_bottom {
  display: flex;
  justify-content: space-between;
  margin: 0 50px;
}

@media (max-width: 950px) {
  .footer_top,
  .footer_bottom {
    margin: 0;
    flex-direction: column;
  }
}

.footer_top {
  align-items: center;
}

@media (max-width: 950px) {
  .footer_top {
    align-items: flex-start;
  }
}

.footer_bottom {
  align-items: center;
  padding: 30px 0;
  gap: 20px;
}

@media (max-width: 800px) {
  .footer_bottom {
    align-items: center;
    flex-direction: column;
  }
}

.footer_bottom_text {
  margin: 0;
  color: var(--black-2);
  text-align: center;
}

.footer .input-line {
  margin-top: 40px;
  position: relative;
}

@media (max-width: 900px) {
  .footer .input-line {
    margin-top: 40px;
  }
}

.footer .input-line:before {
  background: linear-gradient(90deg, #ffffff, rgba(203, 251, 69, 0));
}

.footer .input-line:after {
  background: linear-gradient(-90deg, #ffffff, rgba(203, 251, 69, 0));
}

/* End Footer styles */

/* Start Contact Form */

.contact-form {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
  z-index: 100;
  will-change: transform;
  transition-property: transform;
  transition-duration: 1500ms;
  transition-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.contact-form.visible {
  transform: translate3d(0, 0, 0);
  opacity: 1;
  pointer-events: initial;
  visibility: visible;
}

.contact-form.hidden {
  transform: translate3d(100%, 0, 0);
}

.contact-form_blur {
  flex: 1;
  background-color: rgba(255, 255, 255, 0.2); /* Add some background color with opacity */
  backdrop-filter: blur(6px);
  height: 100%;
}

@media (max-width: 900px) {
  .contact-form_blur {
    display: none;
  }
}

.contact-form_content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--dark-grey);
  height: 100%;
  padding: 60px;
  max-height: 100%;
  overflow-y: auto;
}

@media (max-width: 1100px) {
  .contact-form_content {
    flex: 3;
  }
}

@media (max-width: 900px) {
  .contact-form_content {
    padding: 40px;
  }
}

.contact-form_close-btn {
  position: absolute;
  top: 50px;
  right: 50px;
  width: 54px;
  height: 54px;
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  /* transition-property: opacity, transform, scale; */
  transition-timing-function: ease-in-out;
  transition-duration: 500ms;
}

.contact-form_close-btn:hover {
  scale: 1.2;
}

.contact-form.hidden .contact-form_close-btn {
  scale: 0.5;
}

.contact-form_close-btn .icon-group {
  position: relative;
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
}

.contact-form.hidden .contact-form_close-btn .icon-group {
  border-radius: 100%;
}

.contact-form.visible .contact-form_close-btn .icon-group {
  border-radius: 0;
}

.contact-form_close-btn .icon-ring {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.contact-form.hidden .contact-form_close-btn .icon-ring {
  opacity: 0.4;
}

.contact-form.visible .contact-form_close-btn .icon-ring {
  opacity: 1;
  border-radius: 100%;
  transform: translate(0, 0);
}

.contact-form_close-btn .icon-ring svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
  transform: translate(0, 0);
  top: 0;
  left: 0;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
}

.contact-form.visible .contact-form_close-btn .icon-ring svg {
  animation: ringAppear 1000ms cubic-bezier(0.65, 0, 0.35, 1);
  animation-delay: 1200ms;
  animation-fill-mode: forwards;
}

@keyframes ringAppear {
  30% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    stroke-dashoffset: 0;
  }
}

.contact-form_close-btn .icon-gradient {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(150deg, var(--dark-grey) 0%, var(--dark-grey) 20%, rgba(203, 251, 69, 0) 50%, rgba(203, 251, 69, 0) 100%);
}

.contact-form_close-btn .icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition-property: opacity;
  transition-timing-function: ease-in-out;
  transition-duration: 500ms;
}

.contact-form.visible .contact-form_close-btn .icon {
  opacity: 1;
  transition-delay: 1200ms;
}

.contact-form_content_title {
  margin-top: 0;
  font-size: 54px;
  text-align: left;
  width: 100%;
}

.contact-form_content_form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 60px;
  row-gap: 60px;
  width: 100%;
}

@media (max-width: 900px) {
  .contact-form_content_form {
    column-gap: 30px;
    row-gap: 20px;
  }
}

@media (max-width: 550px) {
  .contact-form_content_form {
    display: flex;
    flex-direction: column;
  }
}


.contact-form_content_form .label {
  position: relative;
}

.contact-form_content_form .label-text {
  font-size: 15px;
  color: #898989;
}

@media (max-width: 900px) {
  .contact-form_content_form .label-text {
    font-size: 13px;
  }
}

.contact-form_content_form .input {
  width: 100%;
  background-color: transparent;
  border: none;
  color: white;
  padding: 5px 0;
}

.contact-form_content_form .input:focus {
  outline: none; /* Removes default outline */
  border: none;  /* Removes border */
}

.contact-form_content_form .input-comment {
  resize: vertical;
}

.contact-form_content_form .label-comment {
  grid-column: 1 / 3;
}

@media (max-width: 900px) {
  .contact-form_content_form .label-comment {
    margin-bottom: 30px;
  }
}

@media (max-width: 900px) {
  .contact-form_content_form .label {
    grid-column: 1 / 3;
  }
}

.contact-form_content_form .input-line {
  background-color: #898990;
  margin: 5px -10px;
}

.contact-form_content_form .input-line::after {
  background: linear-gradient(-90deg, var(--dark-grey), rgba(203, 251, 69, 0));
}

.contact-form_content_form .input-line::before {
  background: linear-gradient(90deg, var(--dark-grey), rgba(203, 251, 69, 0));
}

.contact-form_content_form .error-msg {
  position: absolute;
  color: #b61818;
  bottom: 0;
  margin: 0;
  transform: translateY(22px);
  font-size: 12px;
}

.contact-form .submit-btn {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 20px;
  border: none;
  color: white;
  height: 52px;
  margin-top: -10px;
}

@media (max-width: 550px) {
  .contact-form .submit-btn {
    justify-content: center;
  }
}

.contact-form .submit-btn-text {
  margin: 0;
  cursor: pointer;
}

.contact-form .contact-data p {
  font-size: 15px;
  margin: 10px 0;
}

@media (max-width: 550px) {
  .contact-form .contact-data {
    order: 1;
    margin-top: 50px;
  }
}


.contact-form .contact-data i {
  margin-right: 10px;
}

/* End Contact Form */

/* ====== BUTTON CIRCLE ====== */

.btn-circle {
  border-radius: 50%;
  width: 54px;
  height: 54px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 200ms ease-in-out;
  animation-fill-mode: backwards;
}

/* to trigger animation when hovering parent or sibling element,
add 'btn-circle-parent' or 'btn-circle-sibling' classNames */

.btn-circle:hover,
.btn-circle-parent:hover .btn-circle,
.btn-circle-sibling:hover + .btn-circle {
  scale: 1.2;
}

.btn-circle svg {
  animation: 400ms ease-in-out;
  transform: translate(0, -50%);
}

.btn-circle:hover svg,
.btn-circle-parent:hover .btn-circle svg,
.btn-circle-sibling:hover + .btn-circle svg {
  animation-name: arrowMove;
}

@keyframes arrowMove {
  0% {
    transform: translate(0, -50%);
  }
  20% {
    transform: translate(100%, -50%);
    opacity: 1;
  }
  40% {
    opacity: 0;
    transform: translate(100%, -50%);
  }
  59% {
    opacity: 0;
    transform: translate(-100%, -50%);
  }
  60% {
    opacity: 1;
  }
  100% {
    transform: translate(0, -50%);
  }
}

/* Start Products Carousel */
.product-carousel-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.product-carousel {
  width: 100%;
  max-width: 100vw;
  display: flex;
  overflow-x: auto;
  padding: 20px;
  gap: 20px;
  scroll-snap-type: x mandatory;
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.product-carousel::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Edge */
}

.product-card-placeholder {
  width: calc(16.6% + 55px);
  min-width: calc(16.6% + 55px);
  height: 460px;
  scroll-snap-align: start;
}

@media (max-width: 900px) {
  .product-card-placeholder {
    height: 380px;
  }
}

/* - 20px to align because this section only has 2 cards*/
#products-lighting .product-card-placeholder,
.lighting-page .product-card-placeholder {
  width: calc(16.6% + 55px - 20px);
  min-width: calc(16.6% + 55px - 20px);
}

@media (max-width: 900px) {
  .product-card-placeholder,
  #products-lighting .product-card-placeholder,
  .lighting-page .product-card-placeholder {
    min-width: 1px;
    width: 1px;
  }
}

/* - 20px to align because this section only has 2 cards */
@media (min-width: 641px) and (max-width: 900px) {
  #products-lighting .product-card-placeholder,
  .lighting-page .product-card-placeholder {
    margin-right: -20px;
  }
}

@media (max-width: 641px) {
  .lighting-page .product-card-placeholder {
    display: none;
  }
}

.product-card {
  width: 320px;
  height: 460px;
  scroll-snap-align: start;
  background: var(--medium-grey);
  border-radius: 8px;
  text-align: center;
  padding: 36px 26px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
}

@media (max-width: 900px) {
  .product-card {
    min-width: 280px;
    height: 380px;
    padding: 26px 16px;
  }
}

.product-card:hover {
  background: var(--medium-grey-hover);
}

.product-card_img {
  width: 100%;
  height: 230px;
  border-radius: 8px;
  object-fit: cover;
}

.product-card_title {
  font-size: 20px;
  font-weight: 400;
  color: white;
  text-align: left;
  margin: 0 0 30px;
  text-decoration: none;
  transition: 0.3s;
}

@media (max-width: 900px) {
  .product-card_title {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.product-card:hover .product-card_title {
  color: var(--black-2);
}

.product-card .btn-circle {
  margin-top: 50px;
}

@media (max-width: 900px) {
  .product-card .btn-circle {
    margin-top: 13px;
  }
}

.product-card:hover .btn-circle {
  color: var(--black-2) !important;
}

.product-card .btn-circle svg {
  animation: 300ms ease-in-out;
}

.product-card * {
  pointer-events: none; /* Disable pointer events on all child elements */
}

.product-card {
  pointer-events: auto; /* Re-enable pointer events on the card itself */
}

.product-card .hover-circle {
  position: absolute;
  width: 100px;
  height: 100px;
  background-color: white;
  opacity: 0;
  border-radius: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%), scale(0.3);
  transition: opacity 300ms;
}

.product-card.hovered .hover-circle {
  animation-name: circleAppear;
  animation-duration: 500ms;
  animation-delay: 200ms;
  animation-fill-mode: forwards; /* Keep the final state of the animation */
}

@keyframes circleAppear {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.3;
    transform: scale(3);
  }
}


.section-products .carousel-button {
  position: absolute;
  top: 50%;
  padding: 0;
  background: transparent;
  transform: translateY(-50%);
  border: none;
  cursor: pointer;
  z-index: 1;
  border-radius: 50%;
}

@media (max-width: 900px) {
  .section-products .carousel-button {
    display: none;
  }
}

.section-products .carousel-button.left {
  left: 30px;
  transform: scaleX(-1);
}

.section-products .carousel-button.right {
  right: 30px;
}

@media (max-width: 768px) {
  .section-products .product-card {
    width: 150px;
  }
}

/* End Products Carousel */


.cls-1 {
  fill: url(#linear-gradient);
}

.cls-2 {
  fill: #fff;
}

.cls-3 {
  mask: url(#mask);
}

.cls-4,
.cls-5 {
  fill: none;
}

.cls-4 {
  stroke: #fff;
}

.cls-5 {
  stroke: #fff;
}

.cls-6 {
  fill: #fff;
}

.corner-btns {
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  gap: 10px;
  z-index: 50;
}

@media (max-width: 700px), (max-height: 700px) {
  .corner-btns {
    bottom: 10px; /* don't forget to align skip animation */
    right: 20px;
  }
}


.corner-btns_all,
.corner-btns_big-arrow {
  transition: 400ms ease-in-out;
  position: absolute;
  bottom: 0;
  right: 0;
}

.corner-btns_all {
  display: flex;
  flex-direction: column;
  gap: 10px;
}


.corner-btns.btns-visible .corner-btns_all {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  transition-delay: 300ms;
}

.corner-btns.btns-hidden .corner-btns_all {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
}


.corner-btns.btns-visible .corner-btns_big-arrow {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  transform: translateY(-20px);
}

.corner-btns.btns-hidden .corner-btns_big-arrow {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  transition-delay: 300ms;
}

.corner-btns_btn {
  display: block;
  height: 44px;
  width: 44px;
  border-radius: 50%;
  cursor: pointer;
  border: none;
}

.corner-btns_btn--telegram {
  background: url(../assets/images/telegram.svg) no-repeat center center;
  background-size: 25px;
}

.corner-btns_btn--viber {
  background: url(../assets/images/viber.svg) no-repeat center center;
  background-size: 20px;
}

.corner-btns_btn--telegram,
.corner-btns_btn--viber,
.progress-wrap {
  background-color: #9f9f9fad;
  transition: 300ms;

}

.corner-btns_btn--telegram:hover,
.corner-btns_btn--viber:hover,
.progress-wrap:not(.disabled):hover {
  background-color: #c9c9c9ba;
}

/* ====================== [ Start progress-wrap ] ====================== */
.progress-wrap {
  height: 44px;
  width: 44px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  z-index: 100;
  -webkit-transition: all 400ms linear, opacity 200ms linear;
  -o-transition: all 400ms linear, opacity 200ms linear;
  transition: all 400ms linear, opacity 200ms linear;
  box-sizing: border-box;
  position: relative;
  padding: 0;
  border: none;
}

.progress-wrap.disabled {
  cursor: default;
  opacity: 0.3;
}

.progress-wrap::after {
  position: absolute;
  background: url(../assets/images/original-arrow-up.svg) no-repeat center center;
  background-size: contain;
  font-family: 'Font Awesome 5 Free';
  content: ' ';
  text-align: center;
  line-height: 44px;
  font-size: 13px;
  font-weight: 900;
  left: 50%;
  top: 50%;
  height: 20px;
  width: 20px;
  display: block;
  z-index: 1;
  -webkit-transition: all 400ms linear;
  -o-transition: all 400ms linear;
  transition: all 400ms linear;
  transform: translate(-50%, -50%) rotate(-90deg);
}
.progress-wrap svg path {
  fill: none;
}
.progress-wrap svg.progress-circle path {
  stroke: var(--black-2);
  stroke-width: 5;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 400ms linear;
  -o-transition: all 400ms linear;
  transition: all 400ms linear;
}
/* ====================== [ End progress-wrap ] ====================== */

/* ====================== [ Start button gradient ] ====================== */
.button-gradient {
  width: 54px;
  height: 54px;
  padding: 5px;
  background: var(--black-2);
  border: none;
  cursor: pointer;
  /* transition-property: opacity, transform, scale; */
  transition-timing-function: ease-in-out;
  transition-duration: 500ms;
  border-radius: 50%;
  overflow: hidden;
}

@media (min-width: 641px) {
  .button-gradient {
    display: none;
  }
}

.button-gradient .icon-group {
  position: relative;
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
  border-radius: 0;
}

.button-gradient .icon-ring {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 1;
  border-radius: 100%;
  transform: translate(0, 0);
  border: 1px solid white;
}

.button-gradient .icon-ring svg {
  display: none;/* !!! */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
  transform: translate(0, 0);
  top: 0;
  left: 0;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: ringAppear 1000ms cubic-bezier(0.65, 0, 0.35, 1);
  animation-delay: 1200ms;
  animation-fill-mode: forwards;
}

.button-gradient .icon-gradient {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(150deg, var(--black-2) 0%, var(--black-2) 20%, rgba(203, 251, 69, 0) 50%, rgba(203, 251, 69, 0) 100%);
}

.button-gradient .icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition-property: opacity;
  transition-timing-function: ease-in-out;
  transition-duration: 500ms;
  opacity: 1;
  transition-delay: 1200ms;
  color: white;
}
/* ====================== [ End button gradient ] ====================== */

/* ====================== [ Start input line ] ====================== */
.input-line {
  border: none;
  height: 1px;
  width: 100%;
  background-color: var(--dark-grey);
  z-index: 1;
  opacity: 30%;
  margin: 28px 0;
  margin-top: 0;
  margin-bottom: 0;
  left: 0;
  right: 0;
  transform-origin: 0 50%;
  transition: transform 800ms cubic-bezier(0.33, 1, 0.68, 1);
}

.hidden .input-line {
  transform: scaleX(0);
}

.visible .input-line {
  transition-delay: 1000ms;
  transform: scaleX(1);
}

.input-line:after {
  background: linear-gradient(-90deg, var(--medium-grey-hover), rgba(203, 251, 69, 0));
  content: "";
  position: absolute;
  height: 1px;
  width: 30px;
  right: 0;
  height: 1px;
  z-index: 2;
}

.input-line:before {
  background: linear-gradient(90deg, var(--medium-grey-hover), rgba(203, 251, 69, 0));
  content: "";
  position: absolute;
  height: 1px;
  width: 30px;
  left: 0;
  z-index: 2;
}
/* ====================== [ End input line ] ====================== */

/* Loader.css */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
}

.loader {
  border: 5px solid #f3f3f3; /* Light gray background circle */
  border-top: 5px solid #b1b1b1; /* Blue spinner */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

