.home-page {
}

.section-animated {
  /* temporary */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--dark-grey);
  min-height: 100vh;
  /* temporary */
}

.section-about-us {
}

.section-about-us_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 80%;
  padding: 80px;
  margin-left: auto;
  background-color: var(--black);
  color: white;
  min-height: 100vh;
}

@media (max-width: 900px) {
  .section-about-us_content {
    padding: 60px;
  }
}

@media (max-width: 641px) {
  .section-about-us_content {
    width: 100%;
    padding: 60px 20px;
  }
}

.section-about-us_content_title {
  margin: 0 0 15px;
  font-size: 54px;
}

@media (max-width: 900px) {
  .section-about-us_content_title {
    font-size: 42px;
  }
}

@media (max-width: 641px) {
  .section-about-us_content_title {
    font-size: 30px;
  }
}

.section-about-us_content_text {
  /* max-width: 700px; */
  font-size: 18px;
  line-height: 2;
}

@media (max-width: 900px) {
  .section-about-us_content_text {
    font-size: 16px;
  }
}

/* start section image text */
.section-image-text {
  position: relative;
  background-color: white;
  height: 100vh;
}

.section-image-text_bg-img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section-image-text_content {
  position: absolute;
  z-index: 5;
  padding: 150px 50px 100px 100px;
  color: var(--black);
  background: linear-gradient(to right, #ffffffc4 0%, #ffffffb8 50%, #ffffff57 75%, transparent 100%);
  width: 65vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 1100px) {
  .section-image-text_content {
    padding: 120px 50px 150px;
  }
}

@media (max-width: 1100px) and (orientation: portrait) {
  .section-image-text_content {
    width: 100%;
    min-height: unset;
    justify-content: flex-start;
    background: linear-gradient(to bottom, #ffffffc4 0%, #ffffffb8 15%, #ffffff57 30%, transparent 100%);
  }
}

@media (max-width: 700px), (max-height: 700px) {
  .section-image-text_content {
    padding: 120px 20px 150px;
  }
}

.section-image-text_content_title {
  font-size: 3.6vw;
  font-weight: 700;
  max-width: 50vw;
  margin: 0;
  text-transform: uppercase;
  line-height: 1.3;
}

@media (max-width: 1100px) {
  .section-image-text_content_title {
    font-size: 36px;
    max-width: 550px;
  }
}

@media (max-width: 700px), (max-height: 700px) {
  .section-image-text_content_title {
    font-size: 28px;
    max-width: 450px;
    line-height: 1.2;
  }
}

.section-image-text_content_subtitle {
  margin: 0;
  font-weight: 700;
  margin-top: 10px;
  font-size: 2.2vw;
  line-height: 1.2;
}

@media (max-width: 1100px) {
  .section-image-text_content_subtitle {
    font-size: 24px;
  }
}

@media (max-width: 700px), (max-height: 700px) {
  .section-image-text_content_subtitle {
    font-size: 22px;
  }
}

.section-image-text_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* end section image text */

.section-animated_text {
  overflow: hidden;
}

.section-products {
  min-height: 100vh;
  /* padding-left: calc(16.6% + 26px + 45px); */
  padding: 130px 0;
  display: flex;
  flex-direction: column;
  align-items:  center;
  justify-content: center;
}

@media (max-width: 900px) {
  .section-products {
    min-height: unset;
  }

  #products-lifts {
    padding-bottom: 65px;
  }

  #products-lighting {
    padding-top: 65px;
  }
}

.section-products_title {
  font-size: 54px;
  text-align: left;
  width: 100%;
  color: white;
  padding-left: calc(16.6% + 26px + 45px);
  margin-top: 0;
}

@media (max-width: 900px) {
  .section-products_title {
    font-size: 42px;
    padding-left: 20px;
  }
}

@media (max-width: 450px) {
  .section-products_title {
    font-size: 30px;
  }
}